var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dexWrapper"},[_c('div',{staticClass:"dexBox"},[_c('div',{staticClass:"dexval"},[_c('div',[_vm._v("# "+_vm._s(_vm.info.name)+" #")]),_c('div',{staticClass:"sum_num"},[_vm._v("(共"+_vm._s(_vm.total)+"条内容)")])])]),_c('div',{staticClass:"Main width_1200 margin_auto flex dexBoxm",staticStyle:{"padding-bottom":"20px"}},[_c('div',{staticClass:"MainLeft"},[_c('div',{staticClass:"dexHeader flex align_items_c"},[(_vm.isMobileDevice)?_c('div',{staticClass:"topTitle mobile_padding_15"},[_vm._v("热门资讯")]):_c('div',{staticClass:"headerActice"},[_vm._v(" 相关报道 ")])]),_c('div',[(_vm.isMobileDevice)?_vm._l((_vm.TicketDetails),function(item,index){return _c('div',{key:index,staticClass:"mobile_padding_15 flex align_items_c pointer",class:{ wzitemImageNull: item.pic == null || item.pic == '' }},[(item.type == 1)?_c('PerMobileNews',{attrs:{"item":item}}):_c('perMobileFlash',{attrs:{"item":item}})],1)}):_vm._l((_vm.TicketDetails),function(item,index){return _c('div',{key:index,staticClass:"wzitem flex align_items_c pointer",class:{ wzitemImageNull: item.pic == null || item.pic == '' }},[_c('router-link',{staticStyle:{"display":"flex"},attrs:{"target":"_blank","to":{
								name: item.type == 1 ? '/gw_detail' : '/kuaixun_wz',
								params: { id: item.id },
							}}},[_c('div',[(item.pic != null && item.pic != '')?_c('img',{key:item.pic,attrs:{"src":item.pic,"alt":""}}):_vm._e()]),_c('div',{staticClass:"wzitemL",class:{
									wzitemLImageNull: item.pic == null || item.pic == '',
								}},[_c('div',{staticClass:"wzitemLTitle"},[_c('div',{staticClass:"wzitemLTitle_oneline",staticStyle:{"color":"#000000","font-weight":"bold"}},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"wzitemLTitle_oneline"},[_vm._v(_vm._s(item.illustrate))])]),_c('div',{staticClass:"wzitemLTime flex align_items_c justify_content_sb"},[(item.release_time != null)?_c('div',{staticClass:"wzitemLTime_l"},[_vm._v(" "+_vm._s(item.release_time)+" ")]):_vm._e()])])])],1)}),(_vm.tag)?_c('more',{on:{"infinite":_vm.loadMore}}):_vm._e()],2),(_vm.isMobileDevice)?_c('div',{staticClass:"zhuanti_box"},[_c('hotTopics',{attrs:{"title":"近期专题"}})],1):_vm._e()]),_c('div',{staticClass:"MainRight"},[(_vm.flash_list.length > 0)?_c('div',{staticClass:"MainRight_1 hidden_scroll"},[_vm._m(0),_c('div',{staticClass:"steps kxsteps"},[_c('el-steps',{staticStyle:{"height":"95%"},attrs:{"direction":"vertical","active":1}},_vm._l((_vm.flash_list),function(item,index){return _c('el-step',{key:index},[_c('template',{slot:"description"},[_c('div',{staticClass:"stepsVal"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"flex justify_content_sb align_items_c",staticStyle:{"margin-top":"12px"}},[(item.release_time != null)?_c('div',{staticClass:"timeOut"},[_vm._v(" "+_vm._s(item.release_time)+" ")]):_vm._e(),_c('div',{staticClass:"flex justify_content_sb align_items_c"})])])],2)}),1)],1)]):_vm._e(),_c('div',{staticClass:"MainRight_6 eventWraper"},[_c('div',{staticClass:"MainRight_6_title"},[_vm._v("24小时热文")]),_c('othresou',{staticStyle:{"background":"white"}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dexHeader flex align_items_c"},[_c('div',{staticClass:"headerActice"},[_vm._v(" 相关快讯 ")])])
}]

export { render, staticRenderFns }