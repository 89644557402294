<template>
	<div class="dexWrapper">
		<div class="dexBox">
			<div class="dexval">
				<div># {{ info.name }} #</div>
				<div class="sum_num">(共{{ total }}条内容)</div>
			</div>
		</div>
		<div
			class="Main width_1200 margin_auto flex dexBoxm"
			style="padding-bottom: 20px"
		>
			<div class="MainLeft">
				<div class="dexHeader flex align_items_c">
					<div class="topTitle mobile_padding_15" v-if="isMobileDevice"
						>热门资讯</div
					>
					<div class="headerActice" v-else> 相关报道 </div>
				</div>
				<div>
					<template v-if="isMobileDevice">
						<div
							class="mobile_padding_15 flex align_items_c pointer"
							:class="{ wzitemImageNull: item.pic == null || item.pic == '' }"
							v-for="(item, index) in TicketDetails"
							:key="index"
						>
							<!-- {{ item.title }} -->
							<!-- v-if="item.type == 1" -->
							<PerMobileNews :item="item" v-if="item.type == 1"></PerMobileNews>
							<perMobileFlash :item="item" v-else></perMobileFlash>
						</div>
					</template>
					<template v-else>
						<div
							class="wzitem flex align_items_c pointer"
							:class="{ wzitemImageNull: item.pic == null || item.pic == '' }"
							v-for="(item, index) in TicketDetails"
							:key="index"
						>
							<router-link
								target="_blank"
								:to="{
									name: item.type == 1 ? '/gw_detail' : '/kuaixun_wz',
									params: { id: item.id },
								}"
								style="display: flex"
							>
								<div>
									<img
										:src="item.pic"
										:key="item.pic"
										alt=""
										v-if="item.pic != null && item.pic != ''"
									/>
								</div>

								<div
									class="wzitemL"
									:class="{
										wzitemLImageNull: item.pic == null || item.pic == '',
									}"
								>
									<div class="wzitemLTitle">
										<div
											style="color: #000000; font-weight: bold"
											class="wzitemLTitle_oneline"
											>{{ item.title }}</div
										>
										<div class="wzitemLTitle_oneline">{{
											item.illustrate
										}}</div>
									</div>
									<div
										class="wzitemLTime flex align_items_c justify_content_sb"
									>
										<div class="wzitemLTime_l" v-if="item.release_time != null">
											{{ item.release_time }}
										</div>
									</div>
								</div>
							</router-link>
						</div>
					</template>

					<more @infinite="loadMore" v-if="tag"></more>
				</div>
				<div class="zhuanti_box" v-if="isMobileDevice">
					<hotTopics title="近期专题"></hotTopics>
				</div>
			</div>
			<div class="MainRight">
				<div class="MainRight_1 hidden_scroll" v-if="flash_list.length > 0">
					<div class="dexHeader flex align_items_c">
						<div class="headerActice"> 相关快讯 </div>
					</div>
					<div class="steps kxsteps">
						<el-steps direction="vertical" :active="1" style="height: 95%">
							<el-step v-for="(item, index) in flash_list" :key="index">
								<template slot="description">
									<div class="stepsVal">{{ item.title }}</div>
									<div
										class="flex justify_content_sb align_items_c"
										style="margin-top: 12px"
									>
										<div class="timeOut" v-if="item.release_time != null">
											{{ item.release_time }}
										</div>
										<div class="flex justify_content_sb align_items_c"> </div>
									</div>
								</template>
							</el-step>
						</el-steps>
					</div>
				</div>

				<div class="MainRight_6 eventWraper">
					<div class="MainRight_6_title">24小时热文</div>
					<othresou style="background: white"></othresou>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getTicketDetails, searchMore } from "@/api/home";
import PerMobileNews from "@/components/mobile/perMobileNews";
import perMobileFlash from "@/components/mobile/perMobileFlash";
import hotTopics from "@/components/hotTopics.vue";
export default {
	name: "",
	components: {
		PerMobileNews,
		perMobileFlash,
		hotTopics,
	},
	data() {
		return {
			property: "value",
			TicketDetails: [],
			total: "",
			flash_list: [],
			page: 1,
			size: 25,
			tag: false,
			info: {},
		};
	},
	created() {
		this.getTicketDetails(this.$route.params.id);
		this.searchMore();
	},
	methods: {
		loadMore() {
			this.page++;
			this.getTicketDetails(this.$route.params.id);
		},
		getTicketDetails(id) {
			getTicketDetails({ id: id, page: this.page, size: this.size })
				.then((res) => {
					if (res.code == 200) {
						this.TicketDetails = this.TicketDetails.concat(res.data.data.list);
						this.info = res.data.data.info[0];
						res.data.data.list.length < this.size
							? (this.tag = false)
							: (this.tag = true);
						this.total = res.data.data.total;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		searchMore() {
			searchMore({
				order_type: 3,
				time_type: 5,
				key: this.$route.query.name,
				type: 2,
				page: 1,
				size: 25,
			})
				.then((res) => {
					if (res.code == 200) {
						this.flash_list = res.data.list.slice(0, 5);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
	computed: {
		isMobileDevice() {
			return this.$store.state.app.isMobileDevice;
		},
	},
};
</script>

<style scoped>
.dexWrapper {
	padding-top: 20px;
	background: #fafafa;
}
.Main {
	padding-top: 60px;
}

.MainLeft {
	width: 792px;
	background-color: #fff;
	border-radius: 20px;
}

.MainRight {
	width: 360px;
	margin-left: 30px;
}

.dexBox {
	width: clamp(1000px, calc(100vw * 1200 / 1440), 1200px);
	margin: 0 auto;
	height: 180px;
	border-radius: 20px;
	background-image: url("https://res.metaera.hk/resources/assets/images/dex/dex_bg.png");
	background-size: 100% 100%;
}
.dexval div:nth-child(1) {
	font-size: 38px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
}

.dexval div:nth-child(2) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	margin-left: 5px;
	position: relative;
	margin-top: 10px;
}

.dexval {
	padding-top: 45px;
	width: 100%;
	align-content: center;
	align-items: center;
	text-align: center;
}

.dexHeader {
	padding-left: 20px;
	height: 60px;
	border-radius: 20px 20px 0 0;
	border-bottom: 1px solid #efefef;
}

.MainRight_6 {
	width: 372px;
}

.MainRight_6_title {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #fff;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 8px 8px 0 0;
	padding: 16px 22px;
}
.headerActice {
	position: relative;
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	padding-left: 20px;
}

.headerActice::after {
	position: absolute;
	content: "";
	display: inline-block;
	width: 3px;
	height: 18px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 2px 2px 2px 2px;
	opacity: 0.85;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
}

.MainRight_1 {
	width: 100%;
	background: #ffffff;
	border-radius: 18px 18px 18px 18px;
	opacity: 1;
	height: 580px;
	overflow-y: auto;
	margin-bottom: 45px;
}

.MainRight_2 {
	padding-left: 0;
	margin-top: 20px;
	padding-bottom: 40px !important;
}

.MainRight_2 .wzitem {
	height: 80px;
}

.MainRight_2 .wzitem img {
	width: 130px;
	height: 80px;
}

.MainRight_2 .wzitemL {
	height: 80px;
	margin-left: 14px;
}

.wzitemLTitle_oneline {
	overflow: hidden;
	display: -webkit-box;
	display: -moz-box;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	box-orient: vertical;
	-webkit-line-clamp: 1;
}

.timeOut {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #9b9b9b;
}

.kxsteps {
	padding-left: 16px;
	margin-top: 20px;
}

.kxsteps .stepsVal {
	font-size: 15px;
	margin-top: 0;
}
@media screen and (max-width: 900px) {
	.dexWrapper {
		background: #121212;
	}
	.dexHeader {
		padding-left: 0;
		border: 0;
	}
	.Main {
		padding-top: 10px;
	}
	.MainRight {
		display: none;
	}
	.MainLeft {
		width: 100%;
		background: #121212;
	}
	.dexval {
		width: 100%;
		height: 100%;
		border-radius: 10px;
		margin: 0 auto;
		padding-top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background: linear-gradient(
			10deg,
			#9945ff -37%,
			#8752f3 -7%,
			#5497d5 20%,
			#43b4ca 34%,
			#28e0b9 51%,
			#19fb9b 86%
		);
	}
	.dexval div:nth-child(2) {
		display: none;
	}

	.dexBox {
		margin: 0 calc(100vw * 15 / 375);
		width: auto;
		height: calc(100vw * 138 / 375) !important;
	}
	.topTitle {
		font-size: 18px;
		line-height: 1;
		font-weight: 600;
		color: #fff;
	}
	.dexBoxm .wzitem {
		background: #121212;
		border: 0;
		padding-bottom: 0;
	}
	.zhuanti_box {
		padding-top: 20px;
	}
}
</style>
<style>
.wzitemLImageNull {
	margin-left: 0px;
	width: 100%;
}
.wzitemImageNull {
	height: 100px;
}
.dexBoxm .wzitem {
	padding-bottom: 20px;
	border-bottom: 1px solid #efefef;
	background: white;
}

.dexBoxm .wzitem:nth-last-child(1) {
	border-bottom: none;
}
</style>
<style>
.dexWrapper .el-step__icon-inner {
	display: none;
}
.dexWrapper .steps .el-step__icon {
	width: 9px;
	height: 9px;
}
.dexWrapper .steps .el-step__icon.is-text {
	/* border-color: #000000; */
	/* background: #6974c8; */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	/* border: 4px solid; */
}
.dexWrapper .steps .el-step.is-vertical .el-step__line {
	left: 4px;
}
.dexWrapper .el-step__head.is-wait {
	color: #000000;
	border-color: #dedee1;
}
.dexWrapper .el-step__head.is-finish {
	color: #000000;
	border-color: #dedee1;
}
.dexWrapper .el-step__main {
	height: 99px;
}

.dexWrapper .el-step__description {
	padding-right: 0% !important;
}

.dexWrapper .btnitem:nth-last-child(1) {
	margin-left: 10px;
}
.dexWrapper .el-step__icon-inner {
	display: none;
}
.dexWrapper .el-step__icon {
	border: 2px solid !important;
	width: 8px;
	height: 8px;
	border-color: #000 !important;
}
</style>
